import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { PostContent, PostOther } from "page_components/news"

const Post = ({ pageContext }) => {
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
      }}
    >
      <Breadcrumbs parent="Aktualności" title={pageContext?.data?.title} />
      <PostContent data={pageContext?.data} />
      <PostOther id={pageContext?.data?.id} />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Post
